// Triple hyphens on lines 1 and 2 are required by Jekyll in order to
// process SCSS into CSS.
// 
// Note that your code editor may syntax highlight the first import statement
// below as being in an error state due to the hyphens above which are part of
// Jekyll syntax and not SCSS syntax. An invalid syntax error, especially on
// the first meaningful line of code, might be a false positive.

// dependencies imports
@import '_modules/core/_utils';
@import '_modules/core/_media';
@import '_modules/theme/_config';

.home-banner {
  max-height: 1080*$pxToRem;

  .logo {
    width: 536*$pxToRem;
    height: 292*$pxToRem;

    @include media-mobile-only {
      width: 70%;
      height: auto;
      margin: auto;
    }
  }
}
